import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Typography, Icon, Divider } from 'antd';
import classNames from 'classnames';
import Footer from '../footer';
import RightMenu from '../../components/Partial/MenuInfo/RightMenu';
import groupimg1 from '../../assets/images/screenshots/g-1.png';
import groupimg2 from '../../assets/images/screenshots/g-2.png';
import groupimg3 from '../../assets/images/screenshots/g-3.png';
import groupimg4 from '../../assets/images/screenshots/g-4.png';
import groupimg5 from '../../assets/images/screenshots/g-5.png';
import Zoom from 'react-reveal/Zoom';
/*typography */
const { Title } = Typography;
/*typography */

class Ebox extends React.Component
{
   render()
   {
       return(
            <div className="wrapper">
                <Zoom out>
                <Grid fluid className="search-sp">
                    <Grid container>
                        <Row>
                            <Col xs={12} sm={12}>
                                <Title level={4} className={classNames("search-title")}>how to use Groups?</Title>
                            </Col>
                        </Row>
                    </Grid>
                </Grid>
                <Grid fluid className={classNames("search-btm mt-20 mb-20 col-p")}>
                    <Grid container>
                        <Row>
                            <Col xs={12} sm={9} className={classNames("text-left")}>
                                    
                                    <Title level={4} className={classNames("search-subtitle pb-0")}>
                                    Why Group?</Title>
                                    <Divider />

                                    <p>No more sitting near the park to discuss topics. Simply login and have your discussion noted and recorded in one page. You can add members to the group at any time.</p>
                                    
                                    <div className="text-center"><img src={groupimg1} className="mw-100" alt="guide" /></div>
                                    
                                    
                                    <Title level={4} className={classNames("search-subtitle pb-0")}>
                                        How to invite members to the group?</Title>
                                    <Divider />
                                    
                                    <p><strong>Step 1:</strong> Add a group using the “Add Group” option.</p> 
                                                        
                                    <div className="text-center"><img src={groupimg2} className="mw-100" alt="guide" /></div>
                                    
                                    <p><strong>Step 2:</strong> Rename the group as you wish and then click “Add”</p>
                                    
                                    <div className="text-center"><img src={groupimg3} className="mw-100" alt="guide" /></div>
                                    
                                    <p><strong>Step 3:</strong> Invite the people you want to add to the group</p>
                                    
                                    <div className="text-center"><img src={groupimg4} className="mw-100" alt="guide" /></div>
                                    
                                    <p><strong>Step 4:</strong> Type in the Name &amp; Email ID of the person you want to add to the group.</p>
                                    
                                    <div className="text-center"><img src={groupimg5} className="mw-100" alt="guide" /></div>
                            </Col>

                            <Col xs={12} sm={3} className={classNames("text-left", "ques-bk")}>
                                <Title level={4} className={classNames("ques-title")}>
                                <Icon type="menu" className="ques-icon"/>Recently viewed articles</Title>
                              <RightMenu />
                            </Col>
                        </Row>
                        
                    </Grid>
                </Grid>
                </Zoom>
                {((localStorage.getItem('user_signId')>0) ? '' : <Footer />)}
               
            </div>
       );
   }
}

export default Ebox;