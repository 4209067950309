import React, {Component} from "react";
import loader from "../../assets/images/loading-loader.svg"
import { connect } from 'react-redux';
import {setInitUrl} from "../../appRedux/actions/Auth";

class CircularProgress extends Component {
/* const CircularProgress = ({className}) => <div style={{marginRight : '32em', marginTop: '9em'}} className={`loader ${className}`}>
  <img src={loader} alt="loader"/>
  
</div>; */
componentDidMount () {
 // console.log('login_token', this.props.setInitUrl);
}
render() {
 
  const {className} = this.props;
  let sty;
  if(this.props.user_id>0){
    sty = {marginRight : '12em', marginTop: '15em'}
  }else{
    sty = {marginRight : '32em', marginTop: '9em'}
  }
  
  return (
    
    <div style={sty} className={`loader ${className}`}>
    <img src={loader} alt="loader"/>
    
  </div>
  );

}
}
const mapStateToProps = ({settings, auth}) => {
  const {authUser,displayName, user_id} = auth;
  
  return {
    authUser,displayName,user_id}
}; 
export default connect(mapStateToProps, {setInitUrl})(CircularProgress);
//export default CircularProgress;
