import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
//import classNames from 'classnames';
import {onNavStyleChange, toggleCollapsedSideNav} from "../../appRedux/actions/Setting";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import Leftmenu1 from "../../components/Admin/Partial/leftpushmenu1";


class SidebarLogo extends Component {

  render() {
    const {width, themeType, navCollapsed} = this.props;
    let {navStyle} = this.props;
    if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
      navStyle = NAV_STYLE_DRAWER;
    }
    return (
      <div className="gx-layout-sider-header">

      {(navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) ? <div className="gx-linebar">

        <i
          className={`gx-icon-btn icon icon-${navStyle === NAV_STYLE_MINI_SIDEBAR ? 'menu-unfold' : 'menu-fold'} ${themeType !== THEME_TYPE_LITE ? 'gx-text-white' : ''}`}
          onClick={() => {
            if (navStyle === NAV_STYLE_DRAWER) {
              this.props.toggleCollapsedSideNav(!navCollapsed);
            } else if (navStyle === NAV_STYLE_FIXED) {
              this.props.onNavStyleChange(NAV_STYLE_MINI_SIDEBAR)
            } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
              this.props.toggleCollapsedSideNav(!navCollapsed);
            } else {
              this.props.onNavStyleChange(NAV_STYLE_FIXED)
            }
          }}
        />
      </div> : null}

      <Leftmenu1 />
      <Link to="/" className="gx-site-logo">
        {navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR && width >= TAB_SIZE ?
          <img alt="" src={require("../../assets/images/hexanotes-logonew.png")} className="ptb-admin"/> :
          themeType === THEME_TYPE_LITE ?
            <img alt="" src={require("../../assets/images/hexanotes-logonew.png")} className="ptb-admin" /> :
            <img alt="" src={require("../../assets/images/hexanotes-logonew.png")} className="ptb-admin" />}

      </Link>

    </div>
    );
  }
}

const mapStateToProps = ({settings}) => {
  const {navStyle, themeType, width, navCollapsed} = settings;
  return {navStyle, themeType, width, navCollapsed}
};

export default connect(mapStateToProps, {
  onNavStyleChange,
  toggleCollapsedSideNav
})(SidebarLogo);
