import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Typography, Icon, Divider } from 'antd';
import classNames from 'classnames';
import Footer from '../footer';
import RightMenu from '../../components/Partial/MenuInfo/RightMenu';
import Report1 from '../../assets/images/screenshots/e-report-header.png';
import Report2 from '../../assets/images/screenshots/e-report.png';

import Zoom from 'react-reveal/Zoom';
/*typography */
const { Title } = Typography;
/*typography */

class Ebox extends React.Component
{
   render()
   {
       return(
            <div className="wrapper">
                <Zoom out>
                <Grid fluid className="search-sp">
                    <Grid container>
                        <Row>
                            <Col xs={12} sm={12}>
                                <Title level={4} className={classNames("search-title")}>How to Report Problem?</Title>
                            </Col>
                        </Row>
                    </Grid>
                </Grid>
                <Grid fluid className={classNames("search-btm mt-20 mb-20 col-p")}>
                    <Grid container>
                        <Row>
                            <Col xs={12} sm={9} className={classNames("text-left")}>
                                <Title level={4} className={classNames("search-subtitle pb-0")}>
                                    <Icon type="menu" className="icon-style"/> Report a Problem?</Title>
                                <Divider />
                                <p>After you logged in, select your profile drop box and under the given options select "Report a Problem".</p>
                                
                                <div className="text-center"><img src={Report1} className="mw-100" alt="Report" /></div>
                                <p>
                                This will go to a page where you need to fill in a few details such as your name, email ID, and a brief about the issue you are facing. You can also attach screenshots of your issue by selecting the "Choose File" option. Once these details are filled in just select "Report Bug".
                                </p>
                                <img src={Report2} className="mw-100" alt="Report" />

                                <p>You can also visit "Help & Support" for other <strong>Hexanote</strong> related queries.</p>
                            </Col>

                            <Col xs={12} sm={3} className={classNames("text-left", "ques-bk")}>
                                <Title level={4} className={classNames("ques-title")}>
                                <Icon type="menu" className="ques-icon"/>Recently viewed articles</Title>
                                
                              <RightMenu />
                            </Col>
                        </Row>
                        
                    </Grid>
                </Grid>
                </Zoom>
                {((localStorage.getItem('user_signId')>0) ? '' : <Footer />)}
               
            </div>
       );
   }
}

export default Ebox;