import React from 'react';
import { Form, Input, Button, message } from 'antd';
import axios from "axios";
import {baseURL} from './axiosConfig';
import ReCAPTCHA from "react-google-recaptcha";

/*6Le9FdkZAAAAADl7lQnsmxRuUn8vBh0v_w2JmdzY 6Le9FdkZAAAAALAWCb1A5WCzbOWMMqd-B667b4Ph */
function onChange(value){
  let errEle = document.getElementById('captcha_error');
  errEle.style.display = "none";
  document.getElementById("captchaCheck").value = value;
  let ele = document.getElementsByClassName("register-captcha");
  ele[0].classList.remove("has-error");
}
function onExpired(){
 document.getElementById("captchaCheck").value = 0; 
}

class NormalLoginForm extends React.Component {
  handleSubmit = e => {
    e.preventDefault();
    let captchaVal = document.getElementById('captchaCheck').value;
    this.props.form.validateFields((err, values) => {
      if(captchaVal===0) {
        let errEle = document.getElementById('captcha_error');
        let ele = document.getElementsByClassName("register-captcha");
        errEle.style.display = "block";
        ele[0].classList.add("has-error");
        return false;
      }
      if (!err) {
	axios({
	  method: 'post',
	  url: baseURL + 'api/add_user',
	  data: values,
	  config: { headers: { 'Content-Type': 'multipart/form-data' } }
	}).then((response) => {
	    let sub_status = response.data.status;
	    if(sub_status===1){
	      message.warning('Username already exist');
	    } else if(sub_status===2){
	      message.warning('Email already exist');
	    } else if(sub_status===3){
	      this.props.form.resetFields();
	      message.success('Registration completed successfully');
	    } else {
	      message.error('Something went wrong');
	    }
	});
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form id="hexaRegForm" onSubmit={this.handleSubmit} className="login-form log_form_style">
        <Form.Item >
	   <input type="hidden" id="captchaCheck" value="0" />
	</Form.Item>
        <Form.Item>
          {getFieldDecorator('username', {
            rules: [{ required: true, message: 'Please input your username!' }],
          })(
            <Input placeholder="Name" className="log-formcontrol"/>,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('Email', {
            rules: [{ required: true, email: true, message: 'Please input your email!' }],
          })(
            <Input placeholder="Email" className="log-formcontrol"/>,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }],
          })(
            <Input type="password" placeholder="Password" className="log-formcontrol"/>,
          )}
        </Form.Item>
	
	<Form.Item className="register-captcha">
          <ReCAPTCHA
             size="normal"
	     sitekey="6Le9FdkZAAAAADl7lQnsmxRuUn8vBh0v_w2JmdzY"
             onChange={onChange}
             onExpired={onExpired}
	  />
          <div class="ant-form-explain" id="captcha_error" >Please check the captcha</div>
        </Form.Item>
	<br/>
        <Form.Item className="hide">
          <Button htmlType="submit" className="sub_mit" block>
            sign up for free
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(NormalLoginForm);

export default WrappedNormalLoginForm; 
