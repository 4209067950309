import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Typography, Icon, Divider } from 'antd';
import classNames from 'classnames';
import Footer from '../footer';
import RightMenu from '../../components/Partial/MenuInfo/RightMenu';
import Notebook1 from '../../assets/images/screenshots/n-1.png';
import Notebook2 from '../../assets/images/screenshots/n-2.png';
import Notebook3 from '../../assets/images/screenshots/n-3.png';
import Notebook4 from '../../assets/images/screenshots/n-4.png';
import Zoom from 'react-reveal/Zoom';
/*typography */
const { Title } = Typography;
/*typography */

class Ebox extends React.Component
{
   render()
   {
       return(
            <div className="wrapper">
                <Zoom out>
                <Grid fluid className="search-sp">
                    <Grid container>
                        <Row>
                            <Col xs={12} sm={12}>
                                <Title level={4} className={classNames("search-title")}>How To Use Notebook?</Title>
                            </Col>
                        </Row>
                    </Grid>
                </Grid>
                <Grid fluid className={classNames("search-btm mt-20 mb-20 col-p")}>
                    <Grid container>
                        <Row>
                            <Col xs={12} sm={9} className={classNames("text-left")}>
                                <Title level={4} className={classNames("search-subtitle pb-0")}>
                                What is Notebook?</Title>
                                    <Divider />
                                    <p>A Notebook consists notes or files of the similar subject or topic.</p>
                        
                                    <p><strong>Step 1:</strong> Select the Notebook section to view existing Notebooks or create a new one.</p>
                                    
                                    <div className="text-center"><img src={Notebook1} className="mw-100" alt="Logo" /></div>
                                    
                                    <Title level={4} className={classNames("search-subtitle pb-0")}>How to create a New Notebook?</Title>
                                    <Divider />
                                    
                                    <p><strong>Step 1:</strong> You can create a New Notebook by selecting “Add Notebook” option.</p> 
                                                        
                                    <img src={Notebook2} className="mw-100" alt="Logo" />
                                    
                                    <p><strong>Step 2:</strong> Give a Title for your newly created notebook.</p>
                                    
                                    <div className="text-center"><img src={Notebook3} className="mw-100" alt="Logo" /></div>
                                    
                                    <p><strong>Step 3:</strong> You can now add notes to your notebook by selecting “Add Notes”</p>
                                    
                                    <img src={Notebook4} className="mw-100" alt="Logo" />
                            </Col>

                            <Col xs={12} sm={3} className={classNames("text-left", "ques-bk")}>
                                <Title level={4} className={classNames("ques-title")}>
                                <Icon type="menu" className="ques-icon"/>Recently viewed articles</Title>
                                
                               <RightMenu />
                            </Col>
                        </Row>
                        
                    </Grid>
                </Grid>
                </Zoom>
                {((localStorage.getItem('user_signId')>0) ? '' : <Footer />)}
            </div>
       );
   }
}

export default Ebox;