import React, {Component} from 'react'
import { Menu, Icon, Dropdown, message,  Modal, Input, Button, Form,Table,Popconfirm } from 'antd';
import {Recorder} from 'react-voice-recorder'
import 'react-voice-recorder/dist/index.css'  
import { Grid, Row, Col } from 'react-flexbox-grid';
import QueueAnim from 'rc-queue-anim';
import { connect } from 'react-redux';
import moment from 'moment';
import './recoder.css';
import queryString from 'query-string';
import IntlMessages from "../../../util/IntlMessages";
import {EditOutlined, DeleteOutlined} from '@ant-design/icons';
import ReactAudioPlayer from 'react-audio-player';
//import IntlMessages from "../../../util/IntlMessages";
import axios from "axios";
import {baseURL} from '../../axiosConfig';

const base64url = require('base64-url')

const text = <span>Shared By You</span>;

//const { Option } = Select;
/* const routes = [
  {
    path: 'index',
    breadcrumbName: 'Home',
  },
  {
    path: 'Fileinnerpage',
    breadcrumbName: 'File',
  },
]; 
function itemRender(route, params, routes, paths) {
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={paths.join('/')}>{route.breadcrumbName}</Link>
  );
}*/
/* end breadcrumbs */
console.disableYellowBox = true;
console.warn = () => {}

function handleButtonClick(e) {
  message.info('Click on left button.');
} 

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().endOf('day');
}

function disabledDateTime() {
  return {
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  };
}
/* function fixedEncodeURIComponent(str) {
  return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
    return '%' + c.charCodeAt(0).toString(16);
  });
} */
class SharedRecorder extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      audioDetails: {
          url: null,
          blob: null,
          chunks: null,
          data : [],
          duration: {
            h: 0,
            m: 0,
            s: 0
          }
        },
        audio : '',
        data : [],
  };    
  this.handleAudioUpload = this.handleAudioUpload.bind(this);
}

componentDidMount () {
  document.title = 'FileDrive - Recorder';
  console.log('users', this.props.user_id);
  var s=1;
  //this.fetchData(s);
  //var btn = document.getElementsByClassName("undefined");
  var submit = document.getElementsByClassName("undefined").innerHTML='Save';
  const values = queryString.parse(this.props.location.search)
  const res =  Object.keys(values).length;
  console.log('res', values);
  if(res !== 0){
    const { data, pageNo, noMoreData } = this.state;
    /* let decodeshareId = base64url.decode(values.shareId);
    let decodeemail = base64url.decode(values.token); */
    let decodeshareId = values.shareId;
    let decodeemail = values.token;
    /* Get user check for share mail IDS*/
      if(values.token!==''){
        let formData = new FormData();
        formData.append('token', values.token)
        formData.append('shareId', values.shareId)
        //formData.append('user_id', 2)
        formData.append('shared_id', 1)
        axios({
          method: 'post',
          url: baseURL+'api/recorderSharedListView',
          data: formData,
          config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
          .then((response) => {
            const responseList = [];
            console.log('sss',response.data.msg);
            if(response.data.success == true){
              let fileData = response.data.data;
              for (let i = 0; i < fileData.length; i++) {
                responseList.push({
                  audio_title: fileData[i]['audio_title'],
                  blob_key: fileData[i]['blob_key'],
                  audio_size: fileData[i]['audio_size'],
                  audio_type: fileData[i]['audio_type'],
                  duration: fileData[i]['duration'],
                  created_at: fileData[i]['created_at'],
                  key: fileData[i]['key'],
                  index : 0+i,
                  });
              } 
            }else{
              responseList.push({
                error_title: response.data.msg,
                notes_title: '',
                });
            }
            console.log('sa', responseList);
            this.setState({
              data: [ ...data, ...responseList ],
                pageNo: pageNo + 1
              });
            });
            
      }
  
    /*  end */
    }
 
}
setNewfoldervisible(newfolder,id, name, title, index) {
  let title_audio = title!==''? title : name ;
  this.setState({ newfolder });
  this.setState({ autoid : id });
  this.setState({ title_of_audio : title_audio });
  this.setState({ indexKey : index });
}
setUploadfilesVisible(uploadfiles) {
  this.setState({ uploadfiles });
}
setDeleteVisible(modal3Visible) {
  this.setState({ modal3Visible });
}
fetchData(s) {
  const { data, pageNo, noMoreData } = this.state;
  if (noMoreData) {
    console.log('no more data');
    return;
  }
let id = this.props.location.pathname;
  if(id !== ''){
    let formData = new FormData();
    formData.append('start', s)
    formData.append('end', 10) 
    formData.append('user_id', this.props.user_id) 
    axios({
      method: 'post',
      url: baseURL+'api/GetRecorderList',
      data: formData,
      config: { headers: { 'Content-Type': 'application/x-www-form-urlencoded', Accept: "application/json" } }
    })
      .then((response) => {
        console.log('response', response);
         if(response.data.length <10){
          this.setState({
            noMoreData: true
          });
        }
         else{
        const responseList = [];
        let RemainderData = response.data.List;
        //const noteBookCount = response.data.index;
        //const start = (this.state.pageNo)*10;
        for (let i = 0; i < RemainderData.length; i++) {
          responseList.push({
            audio_title: RemainderData[i]['audio_title'],
            blob_key: RemainderData[i]['blob_key'],
            audio_size: RemainderData[i]['audio_size'],
            audio_type: RemainderData[i]['audio_type'],
            duration: RemainderData[i]['duration'],
            created_at: RemainderData[i]['created_at'],
            key: RemainderData[i]['key'],
            index : 0+i,
            });
        }
        this.setState({
            data: [ ...data, ...responseList ],
            pageNo: pageNo + 1
            
          });
        
         }
          
        })
      .catch((error) => {
      //  console.log(error)
        })
      }
}
handleAudioStop(data){
    console.log('audio', data)
    this.setState({ audioDetails: data });
}

handleSubmit = (e) => {
  //const {data} = this.state;
  let id = this.state.autoid;
  let audio_rename = this.state.title_of_audio;
     if((id!=='undefined') && (id!=='')){
     let formData1 = new FormData();
     formData1.append('id', id)
     formData1.append('audio_rename', audio_rename)
     formData1.append('user_id', this.props.user_id) 
     axios({
       method: 'post',
       url: baseURL + 'api/audioRenameUpdate',
       data: formData1,
       config: { headers: { 'Content-Type': 'multipart/form-data' } }
     })
       .then((response) => {
         
         console.log('response', response);
         if (response.data.status===200){
         message.success('Udated successfully'.toString())
         const updatedArray = [...this.state.data];
         console.log('updatedArray', updatedArray);
         console.log('indexKey', this.state.indexKey);
         updatedArray[this.state.indexKey].audio_title = response.data.datas[0]['audio_title'];
         this.setState({
               data: updatedArray,
           }); 
         this.setNewfoldervisible(false);
         
      }
       })
       .catch((err) => {
         message.error('Unable to Update'.toString())
       })
     }
};

showDeleteConfirm = key => {
  //const {s} = this.state;
  let rowID = key;
  let formData = new FormData();
  formData.append('file_id', rowID)
  formData.append('method', 'Delete')
  formData.append('user_id', this.props.user_id) 
  axios({
    method: 'post',
    url: baseURL+'api/deleteReocrdFiles',
    data: formData,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })
    .then((response) => {
      if (response.data.status === 'success') {
        message.success('File Deleted successfully');
        let formData = new FormData();
        formData.append('start', 1)
        formData.append('end', 15)
        //formData.append('lastFolerID', this.state.fileId)
        formData.append('user_id', this.props.user_id) 
        axios({
          method: 'post',
          url: baseURL+'api/GetRecorderList',
          data: formData,
          config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
      .then((res) => {
        const deleteResponseList = [];
        let fileData = res.data.List;
        const fileCount = res.data.index;
         for (let i = 0; i < fileData.length; i++) {
               
                deleteResponseList.push({
            audio_title: fileData[i]['audio_title'],
            blob_key: fileData[i]['blob_key'],
            audio_size: fileData[i]['audio_size'],
            audio_type: fileData[i]['audio_type'],
            duration: fileData[i]['duration'],
            key: fileData[i]['key'],
            created_at: fileData[i]['created_at'],
            index : fileCount+i,
            });
        } 
          this.setState({data : deleteResponseList });
        /* this.setState({
            data: [ ...deleteResponseList, ...data ],
            pageNo: pageNo + 1
            
          }); */
            
          });
      }
    });
};
handleAudioUpload(file) {
    console.log('file',file);
    const { data } = this.state;
    let stateData = this.state.audioDetails;
    let blobUrl = stateData.url;
    if(blobUrl!='' && blobUrl!=null){
    let blobFile = blobUrl.split('/');
    let blob = stateData.blob;
    let blobSize = blob.size;
    let blobType = blob.type;
    let durationHour = stateData.duration.h;
    let durationminutes = stateData.duration.m;
    let durationSec = stateData.duration.s;
    let duration_hms = durationHour+':'+durationminutes+':'+durationSec;
    let userID = this.props.user_id;
    //console.log('h', durationHour);
    let self = this;
    axios({
      method: 'get',
      url: blobUrl, // blob url eg. blob:http://127.0.0.1:3000/e89c5d87-a634-4540-974c-30dc476825cc
      responseType: 'blob'
  }).then(function(response){
       var reader = new FileReader();
       reader.readAsDataURL(response.data); 
       reader.onloadend = function() {
           var base64data = reader.result;
           let formData = new FormData(); 
           formData.append('blob_url', base64data)
           formData.append('blob_filename', blobFile[3])
           formData.append('blob_size', blobSize)
           formData.append('blob_type', blobType)
           formData.append('duration_hms', duration_hms)
           formData.append('user_id', userID)
           axios({
            method: 'post',
            url: baseURL + 'api/SaveAudioFile',
            data: formData,
            config: { headers: { 'Content-Type': 'application/x-www-form-urlencoded', Accept: "application/json" } }
          })
          .then((response) => {
            console.log('e', response.data);
            const responseList = [];
            message.success(response.data[0].msg);
            let fileData = response.data;
                for (let i = 0; i < fileData.length; i++) {
                  responseList.push({
                    audio_title: fileData[i]['audio_title'],
                    blob_key: fileData[i]['blob_key'],
                    audio_size: fileData[i]['audio_size'],
                    audio_type: fileData[i]['audio_type'],
                    duration: fileData[i]['duration'],
                    key: fileData[i]['key'],
                    created_at: fileData[i]['created_at'],
                    index : i,
                    });
                }
                self.setState({
                  data: [ ...responseList, ...data  ],
                });  
                self.setUploadfilesVisible(false);
            })
         }
    })
  }else{
    message.warning('Press the microphone to record first then save');
  }
}
handleRest() {
    const reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: 0,
        m: 0,
        s: 0
      }
    };
    this.setState({ audioDetails: reset });
  }
  




  render() {
   const { getFieldDecorator } = this.props.form;
   const { data } = this.state;
   const dateFormat = 'MM-DD-YYYY'; 
   const columns = [
    {
      title: 'Title',
      dataIndex: 'audio_title',
      key: 'title',
      render: (text, data) => (data.audio_title!==''? data.audio_title : data.blob_key),
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: 'Audio Player',
      dataIndex: 'player',
      key: 'player',
      render: (text, data) => (<ReactAudioPlayer
                                src={ baseURL+'storage/recorder/' + data.blob_key+'?time=' + new Date().getTime()}
                                autoPlay={false}
                                controls
                                listenInterval={10000}
                              />),
      sorter: (a, b) => a.notes.length - b.notes.length,
    },
    {
      title: 'Created',
      dataIndex: 'created_at',
      key: 'shared',
      sorter: (a, b) => a.shared.length - b.shared.length,
    },
    
  ];
  /* end table */
  const myMenu = (val) => (  
    <Menu>
      <Menu.Item key="1" onClick={() => this.setNewfoldervisible(true,val.key,val.blob_key, val.audio_title,val.index)}>
        Edit
      </Menu.Item>
      <Menu.Item key="2" onClick={() => this.setDeleteVisible(true)}>
      <Popconfirm title="Are you sure delete this file?" onConfirm={() => this.showDeleteConfirm(val.key)}>
            <a href="#/">Delete</a>
            </Popconfirm>
      </Menu.Item>
    </Menu>
  )
    return (
      <div className="wrapper">
        <div className="container mt-64 container1" ref={ref => this.container = ref}>

        <QueueAnim  type={['right', 'left']} interval={[200]} delay={[500]}
          duration={[ 500]}
          ease={['easeInOutQuint']} leaveReverse>
            <Grid fluid className="pl-0" key="1">
                    <Row className="mt-64 po-fixed">
                        <Col xs={12} sm={12} md={12} lg={12} className="mediapr-0">
                            <div className="flex">
                                <h5 style={{ width: '100%' }} class="sideinnertext w-25"><IntlMessages id="sidebar.Recorder"/></h5>
                                <div className="text-right">
                                      
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} className="mediapr-0">
                            {/* table */}
                            <Table columns={columns} 
                            dataSource={data} 
                            pagination={false} 
                            onChange={this.handleChange} />
                            {/* end table */}

                        </Col>
                    </Row>
            </Grid>
            </QueueAnim>
         </div>
    </div>
   );
 }
}

const WrappedFilesForm = Form.create()(SharedRecorder);
const mapStateToProps = ({settings, auth}) => {
  //const {locale, navStyle, themeType, layoutType} = settings;
  const {authUser,displayName, user_id,user_email, l_tok} = auth;
  return {
    authUser,displayName,user_email,user_id,l_tok}
}; 
export default connect(mapStateToProps) (WrappedFilesForm);