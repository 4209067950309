import firebase from "firebase";
import 'firebase/messaging';
// Initialize Firebase
/* const config = {
  apiKey: 'AIzaSyAiUEVQDtLNz7_EciHnIu4H2GZzsihY7X8',
  authDomain: 'hexanotes-5a441.firebaseapp.com',
  databaseURL: 'https://hexanotes-5a441.firebaseapp.com/__/auth/handler',
  projectId: 'hexanotes-5a441',
  storageBucket: '',
  appID :'5a721a57-71a3-410f-bc4e-59015f067055',
  messagingSenderId: '654531498379'
}; */
const config = {
  apiKey: 'AIzaSyDanvCsxW5eWvYbrTT5op8PVzCArR4XBfI',
  authDomain: 'filedrive-293011.firebaseapp.com',
  databaseURL: 'https://filedrive-293011.firebaseapp.com/__/auth/handler',
  projectId: 'filedrive-293011',
  storageBucket: '',
  appID :'5a721a57-71a3-410f-bc4e-59015f067055',
  messagingSenderId: '449892852879'
};

firebase.initializeApp(config);
const auth = firebase.auth();
//const microsoftauth = firebase.auth();
//const messaging = firebase.messaging();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
const microsoftProvider = new firebase.auth.OAuthProvider('microsoft.com');

export {
  auth,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
  microsoftProvider,
};
