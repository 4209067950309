import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Typography, Icon, Divider } from 'antd';
import classNames from 'classnames';
import Footer from '../../components/footer';
import RightMenu from '../../components/Partial/MenuInfo/RightMenu';
import Eboximg1 from '../../assets/images/screenshots/e-box.png';
import Eboximg2 from '../../assets/images/screenshots/step-1.png';
import Eboximg3 from '../../assets/images/screenshots/step-2.png';
import Eboximg4 from '../../assets/images/screenshots/step-1.1.png';
import Eboximg5 from '../../assets/images/screenshots/step-1.2.png';
import Eboximg6 from '../../assets/images/screenshots/step-1.3.png';

import Zoom from 'react-reveal/Zoom';
/*typography */
const { Title } = Typography;
/*typography */

class Ebox extends React.Component
{
   render()
   {
       return(
            <div className="wrapper">
                 <Zoom out>
                <Grid fluid className="search-sp">
                    <Grid container>
                        <Row>
                            <Col xs={12} sm={12}>
                                <Title level={4} className={classNames("search-title")}>how to use files?</Title>
                            </Col>
                        </Row>
                    </Grid>
                </Grid>
                <Grid fluid className={classNames("search-btm mt-20 mb-40 col-p")}>
                    <Grid container>
                        <Row>
                            <Col xs={12} sm={9} className={classNames("text-left")}>
                                <Title level={4} className={classNames("search-subtitle pb-0")}>
                                    What is Files</Title>
                                <Divider />

                                <p>Files can be used to store all your files of any format. Simply login and select “Files” from the given sections.</p>

                                <img src={Eboximg1} className="mw-100" alt="Logo" />
                                
                                <Title level={4} className={classNames("search-subtitle pb-0")}>
                                How to upload files and how to create folders?</Title>
                                <Divider />

                                <p>You can upload files and save files by select the “Add New Files” option. It also allows you to upload multiple files at once. </p> 

                                <p><strong>Step 1:</strong> Select the “Add New Files” option.</p>

                                <img src={Eboximg2} className="mw-100" alt="guide" />

                                <p><strong>Step 2:</strong> Browse the files that needs to be uploaded.</p>
                                
                                <div className="text-center"><img src={Eboximg3} className="mw-100" alt="guide" /></div>

                                <Title level={4} className={classNames("search-subtitle pb-0")}>
                                How to Share Files & Folders?</Title>
                                <Divider />

                                <p>You can also share files and folders with friends and colleagues by selecting the share option.</p>
                                
                                <p><strong>Step 1:</strong> Select the share option from the option box</p>
                               
                                <img src={Eboximg4} className="mw-100" alt="guide" />
                                
                                <p><strong>Step 2:</strong> Type in the email ID and from the drop box you can select if the person only view/edit/or both. After selecting the option you may click “Share”.</p>
                                
                                <div className="text-center"><img src={Eboximg5} className="mw-100" alt="guide" /></div>
                                
                                <Title level={4} className={classNames("search-subtitle pb-0")}>
                                How to Delete Files & Folders?</Title>
                                <Divider />

                                <p>You can delete files & folders by selecting the delete option from the option drop box. You will then get a confirmation message for the deletion of the file or folder.</p>

                                <div className="text-center"><img src={Eboximg6} className="mw-100" alt="guide" /></div>

                            </Col>

                            <Col xs={12} sm={3} className={classNames("text-left", "ques-bk")}>
                                <Title level={4} className={classNames("ques-title")}>
                                <Icon type="menu" className="ques-icon"/>Recently viewed articles</Title>
                                
                                <RightMenu />
                            </Col>
                        </Row>
                        
                    </Grid>
                </Grid>
                </Zoom>
                {((localStorage.getItem('user_signId')>0) ? '' : <Footer />)}
               
            </div>
       );
   }
}

export default Ebox;