import React from 'react';
import { Icon,  message, Breadcrumb} from 'antd';
import { Grid, Row, Col } from 'react-flexbox-grid';
import classNames from 'classnames';

import moment from 'moment';
import folderImg from '../../../assets/images/folders.png';
import axios from "axios";
import {baseURL} from '../../axiosConfig';
import QueueAnim from 'rc-queue-anim';
import queryString from 'query-string' ;
import IntlMessages from "../../../util/IntlMessages";
import Viewer from "react-viewer";
import CircularProgress from "../../CircularProgress/progressLoader";

const base64url = require('base64-url')

console.disableYellowBox = true;
console.warn = () => {}


class Sidemenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    modal1Visible: false,
    modal2Visible: false,
    modal3Visible: false,
    newfolder: false,
    openKeys: ['sub1'],
    previewVisible: false,
    previewImage: "",
    fileList: [],
    data:[],
    pageNo: 0,
    displayCount: 5,
    noMoreData: false,
    s : 0,
    indexKey : '',
    authUser: '',
    img_type:'',
    date: null,
    rowKey: '',
    breadcrumbLinks: [],
    urlFileID:'',
    FileName:'',
    partName:'',
    AddFolder:'',
    displayName:'',
    clicktype:'',
    reverseName:'',
    breadCrumbsLink:'',
    breadCrumbsID:'',
    link:'',
    breadcrumbNameArr : [],
    breadcrumbIdArr : [],
    name:'',
    resultname:'',
    fID:'',
    folderName:'',
    mailPath:'',
    title_error:'',
    urlStr:'',
    emailID:'',
    visible: false,
    loading: false,
    isLoading: false,
    images : '',
    filepdf : '',
    type : '',
    value: 1,
    
  };
  this.fetchData = this.fetchData.bind(this);
  this.getIconType = this.getIconType.bind(this);
  this.breadcrumbsRouteID = this.breadcrumbsRouteID.bind(this);
  this.breadcFileClick = this.breadcFileClick.bind(this);
  this.componentDidMount = this.componentDidMount.bind(this);
  this.setNewpdfvisible = this.setNewpdfvisible.bind(this);
  this.getDatepicker = this.getDatepicker.bind(this);
}

onChange = e => {
  console.log('radio checked', e.target.value);
  this.setState({
    value: e.target.value,
  });
};

getDatepicker(date, dateString) {
 // console.log(dateString);
  this.setState({
    date: dateString,
  });
}

  setModal1Visible(modal1Visible) {
    this.setState({ modal1Visible });
  }

  setModal2Visible(modal2Visible) {
    this.setState({ modal2Visible });
  }

  setModal3Visible(modal3Visible) {
    this.setState({ modal3Visible });
  }

  setUploadfilesVisible(uploadfiles) {
    this.setState({ uploadfiles });
  }

  setNewfoldervisible(newfolder) {
    this.setState({ newfolder });
  }
  setNewpdfvisible1(newpdf) {
    this.setState({ newpdf });
    this.setState({ filepdf : '' });
  }
  UNSAFE_componentWillMount() {
    const { s } = this.state;
    const values = queryString.parse(this.props.location.search)
    this.fetchData(s, values.shareId);
    //localStorage.removeItem('breadcrumbsPath');
  }
  
  componentDidMount () {
  document.title = 'FileDrive - Files';
    /* let breadcrum='';
    let url = this.props.match.url;
    let FileId = this.props.location.pathname;  */
    const values = queryString.parse(this.props.location.search)
    const res =  Object.keys(values).length;
    let displayName = this.props.displayName; 
   // console.log('urlstring', values);
    /* console.log('users', this.props);
    console.log('login_token', this.props.setInitUrl); */
    this.setState({ locationpath : this.props.location.pathname});
    this.setState({ displayName : displayName});
    this.setState({ urlStr : values.fileID});
    this.setState({ emailID : values.uID});
    var s=1;
    this.container.addEventListener('scroll', () => {
      const CONTAINER_HEIGHT = this.container.getBoundingClientRect().height;
      const { top: bottomLineOffsetTop } = this.bottomLine.getBoundingClientRect();

      if (bottomLineOffsetTop <= CONTAINER_HEIGHT) {
        //console.log('load more data');
        this.setState({ s : s});
        this.fetchData(s,values.shareId);
        s++;
      }
      
    });
    
    if((values.fileID!=='') && (values.fileID!==undefined)){
      let decodeval = base64url.decode(values.fileID);
      var regex = new RegExp('/', 'g');
      let dcodepath  = decodeval.replace(regex, ',');
      let decOrgVal =  dcodepath.replace(/^,/, '');
      //console.log('dcodepath', decOrgVal);
      localStorage.setItem('shareId', values.shareId); 
      this.getfileShareInfo(values.shareId)
      let formData = new FormData();
      formData.append('decodeFileId', values.shareId)
      axios({
        method: 'post',
        url: baseURL+'api/getFoldername',
        data: formData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
        .then((response) => {
          //console.log('d',decodeval.split('/')[1]);
          if(values.shareId!=decodeval.split('/')[1]){
          let resul = '/'+response.data[0].datas.folder;
          this.setState({ mailPath: resul });
          this.breadcurms_create(decodeval);
        }
        });  

         
      var lastslashindex = decodeval.lastIndexOf('/');
      var result= decodeval.substring(lastslashindex  + 1);
      result = result.replace("\\", "");
      
      //this.breadcrumbsRouteID(result);
      
    } else {

    let enterBreadcrum = localStorage.getItem('breadcrumbsPath');
    let enterBreadcrumID = localStorage.getItem('breadcrumbsPathID');
    
    if(enterBreadcrum!=null){
    var lastslashindexs = enterBreadcrum.lastIndexOf('/');
    var result1= enterBreadcrum.substring(lastslashindexs  + 1);
    result1 = result1.replace("\\", "");

    var lastslashindexID = enterBreadcrumID.lastIndexOf('/');
    var resultID= enterBreadcrumID.substring(lastslashindexID  + 1);
    resultID = resultID.replace("\\", "");
    
    this.breadcrumbsRouteID(resultID);
    this.breadcFileClickName(result1);
    this.breadcurms_create(enterBreadcrumID);
    }else{
      localStorage.removeItem('breadcrumbsPath');
      localStorage.removeItem('breadcrumbsPathID');
    }
    localStorage.removeItem('userIDS');
      localStorage.removeItem('uIDS');
  }
   
  localStorage.setItem('uIDS', res); 
  if(res !== 0){
  let decodeemail = base64url.decode(values.token);
  localStorage.setItem('decodeemail', values.token); 
  /* Get user check for share mail IDS*/
    if(values.token!==''){
      let formData = new FormData();
      formData.append('decodeemail', values.token)
      axios({
        method: 'post',
        url: baseURL+'api/getUsersCheck',
        data: formData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
        .then((response) => {
          //console.log('resul',response);
          if(response.data.status === 1){
            localStorage.setItem('userIDS', response.data.user_id); 
           } else if(response.data.status === 0){
            localStorage.setItem('userIDS', response.data.status); 
           }
          });
    }

  /*  end */
  }
  /* this.setState({
    loading: true
  }); */
  this.fixViewerInterval = setInterval(() => {
    this.setState({ fixViewer: true });
  }, 3000);
  }

  fetchData(s, shareId) {
   const { data, pageNo,  noMoreData } = this.state;
    if (noMoreData) {
      return;
    }
    let id = this.props.location.pathname;
    if(id !== ''){
      let formData = new FormData();
      formData.append('FileId', shareId)
      formData.append('start', s)
      formData.append('end', 15) 
      formData.append('decode_mail', localStorage.getItem('decodeemail')) 
      formData.append('user_id', 0) 
      this.setState({ isLoading: true }, () => {
      axios({
        method: 'post',
        url: baseURL+'api/GetShareList',
        data: formData,
        config: { headers: { 'Content-Type': 'application/x-www-form-urlencoded', Accept: "application/json" } }
      })
        .then((response) => {
           if(response.data.length <10){
            this.setState({
              noMoreData: true
            });
          }
           else{
          const responseList = [];
          let fileData = response.data.List;
          console.log('fileData', fileData);
          const fileCount = response.data.index;
           for (let i = 0; i < fileData.length; i++) {
                  let icon_di = this.getIconType(fileData[i]['img_type'])
                  
            responseList.push({
              assest_type: fileData[i]['assest_type'],
              title_of_assest: fileData[i]['title_of_assest'],
              encrypted_file: fileData[i]['encrypted_file'],
              file_size: fileData[i]['file_size'],
              img_type: fileData[i]['img_type'],
              file_path: fileData[i]['file_path'],
              key: fileData[i]['key'],
              created_at: fileData[i]['created_at'],
              index : fileCount+i,
              icon : icon_di.name,
              icon_style : icon_di.sty,
              });
          } 
         
          this.setState({
              data: [ ...data, ...responseList ],
              pageNo: pageNo + 1,
              isLoading : false
            });
           }
           
          })
        .catch((error) => {
          //console.log(error)
          })
        })   
        }
  
  }

  getfileShareInfo(values){
    console.log('values', values);
    let formData = new FormData();
    formData.append('FileId', values)
    formData.append('decode_mail', localStorage.getItem('decodeemail')) 
    //formData.append('user_id', this.props.user_id) 
    axios({
      method: 'post',
      url: baseURL+'api/GetShareDataDetails',
      data: formData,
      config: { headers: { 'Content-Type': 'application/x-www-form-urlencoded', Accept: "application/json" } }
    })
      .then((response) => {
       let share_id =  response.data[0].datas.id;
       let expire_type =  response.data[0].datas.expire_type;
       let expire_date =  response.data[0].datas.expire_date;
          this.setState({ shareID: share_id });
          this.setState({ expireType: expire_type });
          this.setState({ expireDate: expire_date });
          console.log('share',this.state.expireDate);
          let formData1 = new FormData();
          formData1.append('shareid', share_id)
          formData1.append('decode_mail', localStorage.getItem('decodeemail')) 
          formData1.append('user_id', this.props.user_id) 
          axios({
            method: 'post',
            url: baseURL+'api/UpdateOpenStatus',
            data: formData1,
            config: { headers: { 'Content-Type': 'application/x-www-form-urlencoded', Accept: "application/json" } }
          })
            .then((response) => {
             //
              })
        })
  }

  breadcrumbsRouteID(FileId, type, breadcrum,fID,flag){
   //this.setState({ breadCrumbsLink : breadcrum})
    const {s,  pageNo} = this.state;  
     if(breadcrum!=null && breadcrum.length>0){
        this.breadcurms_create(fID);
    }  
    if(FileId !== ''){
      let formData = new FormData();
      formData.append('FileId', FileId)
      formData.append('start', s)
      formData.append('end', 15) 
      formData.append('flag', flag) 
      formData.append('share_user_id', 1) 
      this.setState({ isLoading: true }, () => {
      axios({
        method: 'post',
        url: baseURL+'api/GetShareList',
        data: formData,
        config: { headers: { 'Content-Type': 'application/x-www-form-urlencoded', Accept: "application/json" } }
      })
        .then((response) => {
          const responseList = [];
          let fileData = response.data.List;
          const fileCount = response.data.index;
           for (let i = 0; i < fileData.length; i++) {
                  let icon_di = this.getIconType(fileData[i]['img_type'])
            responseList.push({
              assest_type: fileData[i]['assest_type'],
              title_of_assest: fileData[i]['title_of_assest'],
              encrypted_file: fileData[i]['encrypted_file'],
              file_size: fileData[i]['file_size'],
              img_type: fileData[i]['img_type'],
              file_path: fileData[i]['file_path'],
              key: fileData[i]['key'],
              created_at: fileData[i]['created_at'],
              index : fileCount+i,
              icon : icon_di.name,
              icon_style : icon_di.sty,
              });
          }
         this.setState({
              data : responseList,
              pageNo: pageNo + 1,
              isLoading : false
            });
           })
          
        /* .catch((error) => {
          console.log(error)
          }) */
        }); 
        }
  }

  handleUpload = (e) => {
    const { data } = this.state;
    e.preventDefault();
  
    let files = Array.from(e.target.files);
    files.forEach((file) => {
       let reader = new FileReader();
       reader.onloadend = () => { 
      let formData = new FormData();
        formData.append("FileID", this.state.urlFileID);
        formData.append("file", file);
        formData.append('user_id', this.props.user_id) 
    axios({
      method: 'post',
      url: baseURL+'api/saveFileUpload',
      data: formData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
      .then(res => {
        if(res.data.status==='200'){
          message.error(res.data.msg.file[0].toString())
        }else if(res.data[0].status==='200'){
        //if (res.data[0].status==='200'){
           const responseList = [];
            let fileData = res.data;
          for (let i = 0; i < fileData.length; i++) {
            let icon_di = this.getIconType(fileData[i]['img_type']);
            responseList.push({
              assest_type: fileData[i]['assest_type'],
              title_of_assest: fileData[i]['title_of_assest'],
              encrypted_file: fileData[i]['encrypted_file'],
              file_size: fileData[i]['file_size'],
              img_type: fileData[i]['img_type'],
              //file_path: fileData[i]['file_path'],
              key: fileData[i]['key'],
              created_at: fileData[i]['created_at'],
              index : i,
              icon : icon_di.name,
              icon_style : icon_di.sty,
              });
          }
           this.setState({
            data: [ ...responseList, ...data  ],
          }); 

            message.success('File uploaded successfully'.toString())
            this.setUploadfilesVisible(false);
        //}
      }
      })
      .catch(err => {
         message.error('The given data was invalid.'.toString())
      });
     
     }
    reader.readAsDataURL(file); 
});
   }
  
   getIconType(icons){
    let icon_disp;
    let icon_sty;
    if (icons === 'pdf') {
      icon_disp = 'file-pdf';
      icon_sty = { fontSize: '35px', color: '#FF3500' }
    }
    if(icons === 'docx' || icons === 'doc' || icons === 'ppt' || icons === 'pptx'){
      icon_disp = 'file-word';
      icon_sty = { fontSize: '35px', color: '#0078d7' }
    }
    if(icons === 'xlsx' || icons === 'xls' || icons === 'csv'){
      icon_disp = 'file-excel';
      icon_sty = { fontSize: '35px', color: '#1D6F42' }
    }
    if(icons === 'png' || icons === 'jpg' || icons === 'jpeg' || icons === 'svg' || icons === 'gif'){
      icon_disp = 'file-image';
      icon_sty = { fontSize: '35px', color: '#eb2f96' }
    }
    if(icons === undefined || icons === null){
      icon_disp = 'folder';
      icon_sty = { fontSize: '35px', color: '#08c' }
    }
    var obj = {
      name: icon_disp,
      sty: icon_sty,
      
  };
    return obj;
   }
  
  handleMenuClick = row => e => {
    this.setState({ shared_title : row.title_of_assest });
    if(row.title_of_assest!=null){
    this.setState({ permission: row.key });
    this.setState({ indexKey: row.index });
    var ext = row.title_of_assest.lastIndexOf(".")!=='-1'?row.title_of_assest.substring(row.title_of_assest.lastIndexOf(".") + 0, row.title_of_assest.length):'';
    this.setState({ extension: ext });
    if(ext!==''){
      var name = row.title_of_assest.substring(0, row.title_of_assest.lastIndexOf(".") + 0);
      this.setState({ title_of_assest: name });
     
    }else{
      this.setState({ title_of_assest: row.title_of_assest });
    }
  }
   
  };

  handleOnChangePermission = (event) => {
    this.setState({ permissionID: event });
  }
handleShare = (e) => {
     let email_id = this.state.email;
     let file_id_share = '/'+this.state.permission;
     let encode = (this.state.breadCrumbsID==''?base64url.encode(file_id_share):base64url.encode(this.state.breadCrumbsID));
     let email_encode =  base64url.encode(this.state.email);
     let id =  this.state.permission;
        if((email_id!=='undefined') && (email_id!=='')){
        let formData1 = new FormData();
        formData1.append('email', this.state.email)
        formData1.append('module_id', this.state.permission)
        formData1.append('permission', this.state.permissionID)
        formData1.append('assets', 'File')
        formData1.append('module_title', this.state.title_of_assest)
        formData1.append('module', 1)
        formData1.append('expireType', this.state.value)
        formData1.append('expireDate', this.state.date)
        formData1.append('user_id', this.props.user_id) 
        formData1.append('encodeName', encode) 
        formData1.append('email_encode', email_encode) 
        formData1.append('username', this.props.displayName) 
        formData1.append('useremail', this.props.user_email) 
        formData1.append('foldername', this.state.shared_title) 
        formData1.append('id', id) 
        axios({
          method: 'post',
          url: baseURL + 'api/share_email',
          data: formData1,
          config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
          .then((response) => {
            message.success('Email sent successfully'.toString())
            this.setModal1Visible(false);
            this.setState({email: ''});
          })
          .catch((err) => {
            message.error('Unable to Send mail'.toString())
          })
        }
  };
  
  handleSubmit = (e) => {
    const {data} = this.state;
    e.preventDefault();
       this.props.form.validateFields(['title_of_assest'], (err, values) => {
      if (!err) { 
         let formData = new FormData();
        if(this.state.permission!=='' && this.state.permission!==undefined){
          let nameExt = this.state.title_of_assest+this.state.extension;
          formData.append('folder_name', nameExt) 
        }else{
          formData.append('folder_name', this.state.title_of_assest)
        }
        formData.append('FileID', this.state.urlFileID)
        //formData.append('folder_color', this.state.folder_color)
        formData.append('id', this.state.permission)
        formData.append('user_id', this.props.user_id) 
        axios({
          method: 'post',
          url: baseURL + 'api/add_folder_name',
          data: formData,
          config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
        .then((response) => {
          if (response.data.status===200){
            if (this.state.permission) {
              message.success('Folder updated successfully'.toString())
                 const updatedArray = [...this.state.data];
                 updatedArray[this.state.indexKey].title_of_assest = response.data.datas[0]['title_of_assest'];
                 this.setState({
                       data: updatedArray,
                   }); 
                 this.setNewfoldervisible(false);
                 this.props.form.resetFields();
                 this.setState({title_of_assest: ''});
                 this.setState({folder_color: ''});
                 this.setState({permission: ''});
            }else{
              message.success(response.data.msg);
              this.setNewfoldervisible(false);
              this.props.form.resetFields();
              this.setState({title_of_assest: ''})
              this.setState({folder_color: ''});
              const responseList = [];
              let fileData = response.data.datas;
                  for (let i = 0; i < fileData.length; i++) {
                    let icon_di = this.getIconType(fileData[i]['img_type']);
                    responseList.push({
                      assest_type: fileData[i]['assest_type'],
                      title_of_assest: fileData[i]['title_of_assest'],
                      encrypted_file: fileData[i]['encrypted_file'],
                      file_size: fileData[i]['file_size'],
                      img_type: fileData[i]['img_type'],
                      //file_path: fileData[i]['file_path'],
                      key: fileData[i]['key'],
                      created_at: fileData[i]['created_at'],
                      index : i,
                      icon : icon_di.name,
                      icon_style : icon_di.sty,
                      });
                  }
                  this.setState({
                    data: [ ...responseList, ...data  ],
                  });  
              }
            } else if(response.data.status === 0){
              message.error(response.data.msg.toString())
          }else {
            message.error('Unable to add Folder'.toString())
          } 
          
        })
        .catch((err) => {
          message.error('Unable to add Folders'.toString())
        })
         }
    });   
}

breadcurms_create(pathfID){
  //console.log('pathfID', pathfID);
  //console.log('path', path);
 // localStorage.setItem('breadcrumbsPath', path);
  localStorage.setItem('breadcrumbsPathID', pathfID);
  //var nameArr = path!=undefined?path.split('/'):[];
  var idArr = pathfID!==undefined?pathfID.split('/'):[];
  //nameArr = [...new Set (nameArr)];
  idArr = [...new Set (idArr)];
  //console.log('linkID', idArr);
  //console.log('nameArr', nameArr);
  //this.setState({ breadcrumbNameArr : nameArr })
  this.setState({ breadcrumbIdArr : idArr })
  //var link='';
  var linkID='';
  var prv_path='';
  for(var i=1;i<idArr.length;i++){
    if (idArr[i] !== '') {
      //var path='/'+nameArr[1]+'/'+nameArr[2]+'/';//+nameArr[3]+'/';
      var slash='';
     // link+=slash+'/'+prv_path+nameArr[i];
      linkID+=slash+'/'+prv_path+idArr[i];
      //prv_path=prv_path+nameArr[i]+'/';
      //prv_id=prv_id+idArr[i]+'/';
  }
}
localStorage.setItem('breadcrumbsPath', linkID.toString());
/* get folder name */
let formData = new FormData();
formData.append('decodeFileId', linkID.toString())
formData.append('user_id', this.props.user_id) 
axios({
  method: 'post',
  url: baseURL+'api/getFoldername',
  data: formData,
  config: { headers: { 'Content-Type': 'application/x-www-form-urlencoded', Accept: "application/json" } }
})
  .then((response) => {
    let resultname = '/'+response.data[0].datas.folder;
    if(resultname!=='/null'){
    this.setState({ breadCrumbsLink : resultname});
  }
    //this.breadcrumbsRouteID(FileId, 'Clickbreadcrumbs', resultname,fID);
  })
/* end */
/* this.setState({ breadCrumbsLink : '',
  breadCrumbsLink : link
}) */
this.setState({ 
//breadCrumbsID : '',
breadCrumbsID : linkID
})


}

breadcFileClick = (row,flag) => e => {
  e.preventDefault();
  let FileId = row.key;
  let name = this.state.breadCrumbsLink+this.state.name+'/'+row.title_of_assest;
  let fID = this.state.breadCrumbsID+this.state.fID+'/'+row.key;
  this.setState({ fileId : FileId});
  this.setState({ folderName : row.title_of_assest});
  this.setState({ name : name});
  this.setState({ fID : fID});
  this.breadcrumbsRouteID(FileId, 'Clickbreadcrumbs', name,fID,flag);
  this.setState({ urlFileID: FileId });
  this.setState({ 
    FileName: this.state.FileName.concat('/', this.state.FileName),
    //FileName: ''
  });
  this.setState({ clicktype: 'Clickbreadcrumbs' });
  this.setState({reverseName : '' });
};

handlePathClickr = (row,rowID) => e => {
  this.setState({breadCrumbsLink : ''}) 
  this.setState({breadCrumbsID : ''}) 
  this.setState({urlStr : ''}) 
  //let nameSlice = row;
  let IDSlice = rowID;
    /* name */
  /* nameSlice = nameSlice.substr(0, nameSlice.lastIndexOf('/'));
  this.setState({name : nameSlice})
  var lastslashindex = nameSlice.lastIndexOf('/');
  var result= nameSlice.substring(lastslashindex  + 1); */
  /* ID */
  IDSlice = IDSlice.substr(0, IDSlice.lastIndexOf('/'));
  this.setState({fID : IDSlice})
  var lastslashindex = IDSlice.lastIndexOf('/');
  var resultID= IDSlice.substring(lastslashindex  + 1);
  this.setState({ urlFileID: resultID });
  if(resultID ===''){
    this.breadcrumbsRouteID('getAllFile', 'Clickbreadcrumbs');
  }

  this.breadcrumbsRouteID(resultID, 'Clickbreadcrumbs');
 // this.breadcFileClickName(result);
  this.breadcurms_create(IDSlice);
};

setNewpdfvisible(row,newpdf){
    if(row.img_type === 'png' || row.img_type==='jpeg' || row.img_type==='jpg' || row.img_type==='gif' || row.img_type==='svg'){
    const image = [
      {
        src:
        baseURL+'storage/uploads/' + row.encrypted_file
      },
    ]; 
    this.setState({images : image});
    this.setState({ visible: !this.state.visible })
    }else if(row.img_type === 'pdf' ){
      let file = baseURL+'storage/uploads/' + row.encrypted_file
      //let file = 'https://arxiv.org/pdf/quant-ph/0410100.pdf';
      let type=row.title_of_assest;
      this.setState({ type : type });
      this.setState({ filepdf : file });
      this.setState({ newpdf });
      this.setNewpdfvisible(false);
    } else if(row.img_type === 'doc' || row.img_type ==='docx'){
       let file = baseURL+'storage/uploads/' + row.encrypted_file
       //let file = 'http://hexanotes.com/uploads/27_MPi9NtkJ5HAbFyEznqafOBDGp0lCvjLdKxSws8eu4TIV1rZQ3mhRXgWUoY6c.doc';
       let type=row.title_of_assest;
       this.setState({ type : type });
       this.setState({ filepdf : file });
       this.setState({ newpdf });
       this.setNewpdfvisible(false);
        /* Open Document Editor */
        /* this.setState({ documenEditor: true });
        this.setState({ documenEditorTitle: row.title_of_assest });
        this.setState({ documenEditorActionUrl: baseURL+"api/documentEditor/" });
        let baseFilePath = fileURL+'storage/uploads/' + row.encrypted_file; */
        //this.props.history.push('hexaDocument/'+btoa(row.encrypted_file));
    }
    else if(row.img_type === 'xlsx' || row.img_type === 'xls' || row.img_type === 'csv'){
      let file = baseURL+'storage/uploads/' + row.encrypted_file
      //let file = 'https://files.hexanotes.com/uploads/sample.xlsx';
      let type=row.title_of_assest;
      this.setState({ type : type });
      this.setState({ filepdf : file });
      this.setState({ newpdf });
    }
}

breadcFileClickName(fileID){
  this.setState({reverseName : fileID});
  if(fileID ===''){
    fileID = 'file';
  }
  if(fileID !== ''){
    let formData = new FormData();
    formData.append('FileId', fileID)
    formData.append('user_id', this.props.user_id) 
    axios({
      method: 'post',
      url: baseURL+'api/GetFileName',
      data: formData,
      config: { headers: { 'Content-Type': 'application/x-www-form-urlencoded', Accept: "application/json" } }
    })
      .then((response) => {
        if(response.data[0].datas === 'getAllFile'){
          this.breadcrumbsRouteID(response.data[0].datas, 'Clickbreadcrumbs');
        }else {
          let getFileID = response.data[0].datas.id;
          this.breadcrumbsRouteID(getFileID, 'Clickbreadcrumbs');
          this.setState({ urlFileID: getFileID });
          this.setState({ 
          FileName: this.state.FileName.concat('/', this.state.FileName),
          //FileName: ''
          });
        }
        
      })
      .catch((error) => {
       // console.log(error)
        })
      } 
  };

  render() {
    const { isLoading } = this.state;
    const dateFormat = 'MM-DD-YYYY hh:mm:ss'; 
    var currdate1 = new Date().getDate(); //Current Date
    var month = new Date().getMonth() + 1; //Current Month
    var year = new Date().getFullYear(); //Current Year
    const curdate =  month + '-' + currdate1 + '-' + year;
    const cDate = moment(curdate).format("MM-DD-YYYY");

    const expiredDate = moment(this.state.expireDate).format(dateFormat);
    //const {breadcrumbLinks, data} = this.state;
    //const {match, location, themeType, locale, authUser, initURL} = this.props;
    const messageID = <IntlMessages id="sidebar.Files"/>;
    let content;
    if(localStorage.getItem('uIDS')!==0){
      if(localStorage.getItem('userIDS')!=0){
            content = 'show';
      }else{
            content = 'hide';
      }

    }else if(localStorage.getItem('uIDS')===0){
      content  = 'show';
    }
    
   return (
    <div className="wrapper">
    <div className="container mt-64 container1" ref={ref => this.container = ref}>
      <QueueAnim  type={['right', 'left']} interval={[200]} delay={[500]}
      duration={[ 500]}
      ease={['easeInOutQuint']} leaveReverse>
        {/*  <BrowserRouter > */}
      <Grid fluid className="pl-0" key="1"> 
          <Row>
          <Col xs={12} sm={12} md={12} lg={12} className="mediapr-0">
          <Row>
          <div className="flex" style={{width: '100%'}}>
                            <Col xs={12} sm={2}>
  <h5 className="sideinnertext w-25" >{messageID} </h5>        

      </Col>

      
                </div>
                </Row>
                 
                 <Row>
                    <div className="flex" style={{width: '100%'}}>
                      <Col xs={12}>
                      { (cDate <= expiredDate) ?
                          <Breadcrumb>
                          <Breadcrumb.Item >
                           {this.state.breadCrumbsLink}  
                          </Breadcrumb.Item>
                      </Breadcrumb>: '' }
                    {/*  <Breadcrumbs/>  */}
                </Col> 
                </div>
                </Row>
		             
        <table className="w-100 table_design">
        <tbody>
         {this.state.data.map((val, row) => {
           //let FileNames = this.state.FileName.concat('/', this.state.FileName);
           /* let reverseNames = '';
           if(this.state.reverseName !=''){
            reverseNames = this.state.reverseName+'/';
           } */
           if(cDate <= expiredDate){
           var Icons_list = (val.file_size === 0) ? <img className={classNames("liimg", "w-100")} src={folderImg} alt="img" /> : <Icon twoToneColor="#eb2f96" style={val.icon_style} type={val.icon} theme="filled" />;  
          return (<tr key={val.key}>
            <td className="text-left">
            <ul className="single_ul">
                <li className="li-img">
               {Icons_list} 
                </li>
                <li className="single-li text-left">
                 
                { val.file_size === 0  ? 
                      <span className="anc-text" onClick={this.breadcFileClick(val,1)}>{val.title_of_assest}</span>
                       : (this.state.urlStr!=='') ? <span onClick={() => this.setNewpdfvisible(val,true)}>{val.title_of_assest} </span>: val.title_of_assest }
         <p className="single-para"> Created   {moment(val.created_at).format(dateFormat) }</p>
                </li>
              </ul>
            </td>
           
       <Viewer
          visible={this.state.visible}
          noFooter={true}
          onClose={() => {
            this.setState({ visible: false });
          }}
          images={this.state.images}
          />
         
         

        <td>
            <ul className="single_ulend">
                    {val.file_size === 0 ? '':
                    <li className="w-30">
                        <a href={val.file_path} rel="noopener noreferrer" target="_blank" download><Icon type="download" /></a>
                    </li>
                    }
            </ul>
            </td>
          </tr>
         )
          } else {  return (<div >The page you're trying to view doesn't exist</div>); }
         })} 
        
        </tbody>
      </table> 
      {isLoading ?
              <div className="gx-loader-view">
                <CircularProgress/>
              </div> : null}
                 
                    </Col>
                </Row>
            </Grid>
            {/* </BrowserRouter> */}
            </QueueAnim>
        </div>
          <div ref={ref => this.bottomLine = ref}></div>
        {/* upload Files */}
        
      </div>
  );
  }
}




export default Sidemenu;