import React, {Component} from "react";
import {connect} from "react-redux";
import {Menu,Icon} from "antd";
import {Link} from "react-router-dom";
import SidebarLogo from "./SidebarLogo";
import { CloudFilled, CustomerServiceFilled,ShareAltOutlined,PlayCircleOutlined  } from '@ant-design/icons';
import Auxiliary from "../../util/Auxiliary";
import {baseURL}  from '../../components/axiosConfig';
import axios from "axios";
//import UserProfile from "./UserProfile";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR
 
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

class SidebarContent extends Component {
      constructor(props){
            super(props);
            this.state = {
                  access_token :  ''
            };
            
          }
  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  componentDidMount () {
      let formData = new FormData();
      formData.append('user_id', this.props.user_id)
      axios({
        method: 'post',
        url: baseURL+'api/googleTokenbyuserID',
        data: formData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
        .then((response) => {
          if (response.data.status === 1) {
           this.setState({access_token : response.data.google_access_token})
          }
        });
  }

  handleMenuClick = row => e => {
        if(row===2){
            localStorage.removeItem('breadcrumbsPath');
            localStorage.removeItem('breadcrumbsPathID');
        }    
  }
  render() {
    //const { access_token } = this.state;
    //const { pathname} = this.props;
    //const selectedKeys = pathname.substr(1);
    //const defaultOpenKeys = selectedKeys.split('/')[1];
    /*let driveLink='';
    if((this.state.access_token!=='') && (this.state.access_token!==null) && (this.state.access_token!=='null'))
   {
         driveLink = <Link to="/GoogleDrive"><Icon type="google" /><IntlMessages id="sidebar.GoogleDrive"/></Link>;
   }    else {
         driveLink = <a href={baseURL+'api/Oauth2callback/'+this.props.user_id}><Icon type="google" /><IntlMessages id="sidebar.GoogleDrive"/></a>;
   }   */
   
//const { SubMenu } = Menu;
    return (
      <Auxiliary>
         <SidebarLogo />
                  <div className="gx-sidebar-content">
                        <Menu className="innersidemenu d-none" style={{ width: '200px', display: 'block', height: '90vh' }} 
                              mode="inline">
                              <Menu.Item key="2" onClick={this.handleMenuClick(2)}>
                                    <Link to="/file"><Icon type="folder" />
                                    <IntlMessages id="sidebar.Files"/></Link>
                              </Menu.Item>
                              <Menu.Item key="15" onClick={this.handleMenuClick(2)}>
                                    <Link to="/SharedFiles"><ShareAltOutlined />
                                    <IntlMessages id="sidebar.Shared"/></Link>
                              </Menu.Item>
                              <Menu.Item key="3">
                                    <Link to="/Notes"><Icon type="calendar" /> 
                                    <IntlMessages id="sidebar.Notes"/></Link>
                              </Menu.Item>
                              {/*<Menu.Item key="4">
                                    <Link to="/Notebook"><Icon type="calendar" />
                                    <IntlMessages id="sidebar.Notebook"/></Link>
                              </Menu.Item>*/}
                              {/* <Menu.Item key="5">
                                    <Link to="/Markeddd"><Icon type="calendar" />
                                    <IntlMessages id="sidebar.Marked"/></Link>
                              </Menu.Item>  */}
                               <Menu.Item key="6">
                                    <Link to="/Photos"><Icon type="file-image" />
                                    <IntlMessages id="sidebar.Photos"/></Link>
                              </Menu.Item>
                              <Menu.Item key="7">
                                    <Link to="/Remainder"><Icon type="usergroup-delete" />
                                    <IntlMessages id="sidebar.Remainder"/></Link>
                              </Menu.Item>
                              <Menu.Item key="14">
                                    <Link to="/Recorder"><CustomerServiceFilled />
                                    <IntlMessages id="sidebar.Recorder"/></Link>
                              </Menu.Item>
                              {/* <SubMenu className="mobile_view_menu"
                                    key="sub0"
                                    title={
                                    <span>
                                    <Icon type="appstore" />
                                    <span>Photos</span>
                                    </span>
                                    }
                              >
                                    <Menu.Item key="0">
                                          <Link to="/Photos"><Icon type="file-image" className="mr-10" />
                                          <IntlMessages id="sidebar.Photos"/></Link>
                                    </Menu.Item>
                                    <Menu.Item key="1">
                                          <Link to="/Albums"><Icon type="question-circle" className="mr-10" />
                                          <IntlMessages id="sidebar.Albums"/></Link>
                                    </Menu.Item>
                              </SubMenu> */}
                              {/*<Menu.Item key="7">
                                    <Link to="/Group"><Icon type="usergroup-delete" />
                                    <IntlMessages id="sidebar.Group"/></Link>
                              </Menu.Item>*/}
                              
                              {/* <Menu.Item key="14">
                                    <Link to="/Working"><CustomerServiceFilled />
                                    Working</Link>
                              </Menu.Item> */}
                              <Menu.Item key="9">
                                    <Link to="/SharePoint"><Icon type="windows" />
                                    <IntlMessages id="sidebar.Sharepoint"/></Link>
                              </Menu.Item>
                              <Menu.Item key="10">
                                    <Link to="/Dropbox"><Icon type="dropbox" />
                                    <IntlMessages id="sidebar.Dropbox"/></Link>
                              </Menu.Item>
                              { this.state.access_token!=='' && (this.state.access_token!==null) && (this.state.access_token!=='null') ? 
                                    <Menu.Item key="11">
                                    <Link to="/GoogleDrive"><Icon type="google" /><IntlMessages id="sidebar.GoogleDrive"/></Link>
                              </Menu.Item>: '' }
                              
                              <Menu.Item key="12">
                                    <Link to="/FileCloud"><CloudFilled />
                                    <IntlMessages id="sidebar.FileCloud"/></Link>
                              </Menu.Item>
                              <Menu.Item key="8">
                                    <Link to="/Trash"><Icon type="delete" />
                                    <IntlMessages id="sidebar.Trash"/></Link>
                              </Menu.Item>
                              <Menu.Item key="16">
                                    <Link to="/Video"><PlayCircleOutlined  />
                                    <IntlMessages id="sidebar.Video"/></Link>
                              </Menu.Item>
                              {/*<Menu.Item key="12">
                                    <Link to="/Myrefpage"><Icon type="dropbox" />
                                    <IntlMessages id="sidebar.Myrefpage"/></Link>
                              </Menu.Item>
                              <Menu.Item key="13">
                                    <Link to="/Invoice"><Icon type="dropbox" />
                                    <IntlMessages id="sidebar.Invoice"/></Link>
                              </Menu.Item>
                              <Menu.Item key="14">
                                    <Link to="/Working"><Icon type="dropbox" />
                                    Working</Link>
                              </Menu.Item>
                              <SubMenu className="mobile_view_menu"
                                    key="sub1"
                                    title={
                                    <span>
                                    <Icon type="appstore" />
                                    <span>Admin Console</span>
                                    </span>
                                    }
                              >
                                    <Menu.Item key="0">
                                          <Link to="/Adminconsole"><Icon type="user" className="mr-10" /><IntlMessages id="sidebar.Insights"/></Link>
                                    </Menu.Item>
                                    <Menu.Item key="1">
                                          <Link to="/Members"><Icon type="question-circle" className="mr-10" /><IntlMessages id="sidebar.Member"/></Link>
                                    </Menu.Item>
                                    <Menu.Item key="2">
                                          <Link to="/Billing"><Icon type="setting" className="mr-10" /><IntlMessages id="sidebar.Billing"/></Link>
                                    </Menu.Item>
                              </SubMenu>*/}
                        </Menu>
                  </div>
            
     </Auxiliary>
     );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({settings, auth}) => {
  const {navStyle, themeType, locale, pathname} = settings;
  const {authUser,displayName, user_id, access_token} = auth;
  return {navStyle, themeType, locale, pathname, authUser,displayName,user_id, access_token}
};
export default connect(mapStateToProps)(SidebarContent);
