import React from 'react';
import { Menu, Icon, Dropdown, message, Modal, Input, Button, Form, Select,Popconfirm, DatePicker } from 'antd';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import axios from "axios";
import {baseURL} from '../../axiosConfig';
//import './index.css';
import moment from 'moment';
import queryString from 'query-string' ;
import QueueAnim from 'rc-queue-anim';
import IntlMessages from "../../../util/IntlMessages";
import { ChromePicker} from 'react-color'
const base64url = require('base64-url')

//console.log('rest', ClassicEditor.builtinPlugins.map( plugin => plugin.pluginName ));
function handleButtonClick(e) {
  message.info('Click on left button.');
  console.log('click left button', e);
}
/* select */
const { Option } = Select;
//const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
// function onChange(value) {
//   console.log(`selected ${value}`);
// }
// function onBlur() {
//   console.log('blur');
// }

// function onFocus() {
//   console.log('focus');
// }

// function onSearch(val) {
//   console.log('search:', val);
// }
/* end select */


class Note extends React.Component {
/* modal window */  
constructor(props) {
  super(props);
  this.state = {
    isFActive :false,
    modal1Visible: false,
    modal2Visible: false,
    modal3Visible: false,
    modal4Visible: false,
    modal5Visible: false,
    modal6Visible: false,
    openKeys: ['sub1'],
    noteData:[],
    data : '',
    //notebooks: [],
    pageNo: 0,
    displayCount: 5,
    noMoreData: false,
    s : 0,
    sts:'',
    contents:'',
    value:'',
    currdate:'',
    markedIcon : '',
    menuMethod : '',
    remainderDate:'',
    remainDate:null,
    displayColorPicker : false,
    defaultColor : '#999',
    changeColor : '#999',
    color : {
      r : "0",
      g : "9",
      b : "153",
      a : "1"
    } 
    };
  
  this.handleClose = this.handleClose.bind(this);
  this.getDatepicker = this.getDatepicker.bind(this);
}
  
  setModal1Visible(modal1Visible) {
    this.setState({ modal1Visible });
  }
  setModal2Visible(modal2Visible) {
    this.setState({ modal2Visible });
  }
  setModal3Visible(modal3Visible) {
    this.setState({ modal3Visible });
  }
  setModal4Visible(modal4Visible) {
    this.setState({ modal4Visible });
  }
  setModal6Visible(modal6Visible, val) {
    let contents = val.notes;
    //let contents = val.notes.replace(/<[^>]+>/g, '');
    this.setState({ value : val });
    this.setState({ notes_title : val.notes_title});
    this.setState({ notes : contents});
    this.setState({ modal6Visible });
   }
  fullScreen(e){
   // console.log(this.state.isFActive);
    this.setState({ isFActive: !this.state.isFActive })
  }
/* end modal window */

getDatepicker(date, dateString) {
    this.setState({ remainderDate : dateString });
  }

componentWillMount() {
  let pathURL = this.props.location.pathname; 

  }
  componentDidMount() {
    document.title = "FileDrive - Sticky pad"
    const values = queryString.parse(this.props.location.search)
    const res =  Object.keys(values).length;
    console.log('res', values);

    let pathURL = this.props.location.pathname; 
    var currdate = new Date().getDate(); //Current Date
    var month = new Date().getMonth() + 1; //Current Month
    var year = new Date().getFullYear(); //Current Year
    var s=1;
    this.container.addEventListener('scroll', () => {
      const CONTAINER_HEIGHT = this.container.getBoundingClientRect().height;
      const { top: bottomLineOffsetTop } = this.bottomLine.getBoundingClientRect();

      if (bottomLineOffsetTop <= CONTAINER_HEIGHT) {
        console.log('load more data');
        this.setState({ s : s});
        this.fetchData(s, pathURL);
        s++;
      }
    });
    this.setState({
      //Setting the value of the date time
      currdate: month + '-' + currdate + '-' + year
    });
    if(res !== 0){
      const { noteData, pageNo, noMoreData } = this.state;
      /* let decodeshareId = base64url.decode(values.shareId);
      let decodeemail = base64url.decode(values.token); */
      let decodeshareId = values.shareId;
      let decodeemail = values.token;
      /* Get user check for share mail IDS*/
        if(values.token!==''){
          let formData = new FormData();
          formData.append('token', decodeemail)
          formData.append('shareId', decodeshareId)
          //formData.append('user_id', 2)
          formData.append('shared_id', 1)
          axios({
            method: 'post',
            url: baseURL+'api/stickySharedListView',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } }
          })
            .then((response) => {
              const responseList = [];
              console.log('sss',response.data.msg);
              if(response.data.success == true){
                let fileData = response.data.data;
                const fileCount = response.data.index;
                for (let i = 0; i < fileData.length; i++) {
                  responseList.push({
                    key: fileData[i]['key'],
                    notebooks_id: fileData[i]['notebooks_id'],
                    notes_title: fileData[i]['notes_title'],
                    notes: fileData[i]['notes'],
                    remainder_date: fileData[i]['remainder_date'],
                    color_code: fileData[i]['color_code'],
                    created_by: fileData[i]['created_by'],
                    created_at: fileData[i]['created_date'],
                    record_status: fileData[i]['record_status'],
                    //index : fileCount+i,
                    });
                } 
              }else{
                responseList.push({
                  error_title: response.data.msg,
                  notes_title: '',
                  });
              }
              console.log('sa', responseList);
              this.setState({
                noteData: [ ...noteData, ...responseList ],
                  pageNo: pageNo + 1
                });
              });
              
        }
    
      /*  end */
      }
    /* axios({
      method: 'post',
      url: baseURL + 'api/getNotebookData',
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    })
  .then((response) => {
    this.setState({
      notebooks: response.data
    }); 
  }); */
  } 
  

  
  /* handleOnChangeNotebook = (e) => {
       this.setState({ notebookID: e });
  } */
  colorCodeStyle = color => {
    return {
      borderColor: color,
      //background: myColor,
    }
  }
  
  

  handleOnChangePermission = (event) => {
    this.setState({ notePermissionID: event });
  }
  
  

  handleClose = e => {
    let CurrentID = e.currentTarget.dataset.id;
      if(CurrentID === 1)
        this.setState({ modal1Visible: false });
      if(CurrentID ===2)
        this.setState({ modal2Visible: false });
      if(CurrentID === 3)
        this.setState({ modal3Visible: false });
      if(CurrentID === 5)
        this.setState({ modal6Visible: false });
     };

     onHandleShowColorPicker = () => {
       this.setState({ displayColorPicker : true});
     };
     onHandleCloseColorPicker = () => {
      this.setState({ displayColorPicker : false});
     };
     onChangeColorPicker = color => {
      this.setState({ color : color.rgb, changeColor : color.hex});
     }

  render() {
    const { loading} = this.state;
    const { getFieldDecorator } = this.props.form;
    const dateFormat = 'MM-DD-YYYY'; 
    const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
    /* let notebookList = notebooks.length > 0
      && notebooks.map((item, i) => {
        return (
          <Option key={i} value={item.key}>{item.notebook_title}</Option>
        )
      }, this);
    let currentnotebookValue = this.state.notebookID || ""; */
    const currentDate = moment(this.state.currdate).format("MM-DD-YYYY");
    var currdate1 = new Date().getDate(); //Current Date
    var month = new Date().getMonth() + 1; //Current Month
    var year = new Date().getFullYear(); //Current Year
    const curdate =  month + '-' + currdate1 + '-' + year;
    const cDate = moment(curdate).format("MM-DD-YYYY");
    
    
    return (
        <div className="wrapper notes-page">
          <div className="container mt-64 container1" ref={ref => this.container = ref}>
          <QueueAnim  type={['right', 'left']} interval={[200]} delay={[500]}
          duration={[ 500]}
          ease={['easeInOutQuint']} leaveReverse>
            <Grid fluid key="1">
                <Row className="po-fixed">
                    <Col xs={12} sm={2}>
                        <h5 className="sideinnertext"><IntlMessages id="sidebar.Notes"/></h5>
                    </Col>
                </Row>
               <Row>
                {this.state.noteData.map((allval, row) => {
                 if((allval.notes_title!=='')){
                  return (
                    <Col xs={12} sm={4} md={3} className="text-left notes-block notes-block1-all">
                   <div class="clearfix"> </div>
                  <div class="div-sec all" style={this.colorCodeStyle(allval.color_code)} >
                    <div class="m-title">Created  { moment(allval.created_at).format(dateFormat) } {this.state.markedIcon}</div>
                        <a href="javascript:void(0)" title="View Detail" class="viewdetail" onClick={() => this.setModal6Visible(true, allval)}>
                          <div class="div-title">

                            <span class="notes_title">{ allval.notes.replace(/<[^>]+>/g, '').substring(0,15)} </span> 
                              <span className="book_icon"><Icon type="book" className={(allval.record_status===2 ? 'mr-5 ml-5 yellow-clr' : 'mr-5 ml-5 black-clr')} /></span>
                          </div>

                          <div class="c-sec"><p>{renderHTML(allval.notes)} </p></div>
                          <p>{allval.notes_title}</p>
                          </a>
                        
                    </div>
                    
                </Col>
                )
              } else {
                return (<Col xs={12} sm={4} md={3} className="text-left notes-block notes-block1-all">{allval.error_title}</Col>);
              }
              
              })}
                  
                </Row>
            </Grid>

            </QueueAnim>
      </div>
      <div ref={ref => this.bottomLine = ref}></div>
        {/* modal1 */}
        <Modal
        className={(this.state.isFActive) ? 'full-width mod-height' : 'n-width mod-height'}
        title={"Add Your Sticky pad"}
        style={{ top: 20 }}
        visible={this.state.modal1Visible}
        onOk={() => this.setModal1Visible(false)}
        onCancel={() => this.setModal1Visible(false)}
        maskClosable={false}
        footer={[
                        
            <Button key="submit" type="primary" loading={loading} onClick={this.handleCreate} className="share_icon btn-primary">
            <Icon type="check" />Done
            </Button>,
            <Button key="submit" type="button" loading={loading} data-id="1" onClick={this.handleClose} className="share_icon cancel_icon">
            <Icon type="delete" />Discard
            </Button>,
        ]}>
          <Form>
            <Row>
              
              <div className="cust-detail">
                <Row> 
                  <Col xs={12} sm={4}>
                      <Form.Item label="Title">
                      {getFieldDecorator('note_title', {
                        initialValue : this.state.note_title,
                      rules: [{ required: true, message: 'Please input your title!' }],
                      })(<Input placeholder="title" onChange={e => this.setState({ note_title: e.target.value })}
                autoComplete="off" />)}
                  <Input type="hidden" onChange={e => this.setState({ key: e.target.value })} value={this.state.key} />
                      </Form.Item>
                  </Col>
                  <Col xs={12} sm={4}>
                     <Form.Item label="Color Picker">
                    {/*  {getFieldDecorator('color_code', {
                        initialValue : this.state.color_code,
                      rules: [{ required: true, message: 'Please input your color code!' }],
                      })(<Input placeholder="color code" onChange={e => this.setState({ color_code: e.target.value })}
                autoComplete="off" />)} */}
                <div className="color-picker-container">
                   {/*  <label>{this.props.title}</label> */}
                    {/* <div style={this.props.labelStyle} /> */}
                    <div 
                    style={{backgroundColor : this.state.changeColor}}
                    className={"color-picker-color-background"}
                    />
                <Input 
                readOnly
                style={this.props.colorTextBoxStyle}
                name="color-picker-text" 
                type="text"
                className={"color-picker-text"} 
                onClick={() => this.onHandleShowColorPicker()}
                value={this.state.changeColor} 
                />
                {this.state.displayColorPicker && ( 
                    <div className={"color-picker-palette"}>
                      <div className={"color-picker-cover"}
                      onClick={() =>this.onHandleCloseColorPicker() }
                      />
                      <ChromePicker 
                      color={this.state.color}
                      onChange={this.onChangeColorPicker}/>
                    </div>
                  )}
              </div>
                
                      {/*  {getFieldDecorator('notebook', {
                         initialValue : currentnotebookValue,
                        rules: [
                          { required: true, message: 'Please select your notebook title!' },
                        ],
                      })(<Select
                            onChange={this.handleOnChangeNotebook}
                            showSearch
                            style={{ width: 300 }}
                            placeholder="Select a Notebook"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                            >
                          {notebookList}
                          </Select>)} */}
                      </Form.Item>  
                  </Col>
                  <Col xs={12} sm={4}>
                      <div className="text-right mb-10 mt-15">
                        <span  onClick={this.fullScreen.bind(this)}><Icon type="fullscreen" /></span>
                      </div>
                  </Col>
                </Row>
              </div>
 
              <CKEditor
                  editor={ ClassicEditor }
                  data={this.state.data}
                  config={{ 
                    //toolbar: [ 'heading', '|', 'bold', 'italic', '|', 'link', 'bulletedList', 'numberedList', 'blockQuote', '|', 'imageUpload', '|', "mediaEmbed", '|', 'undo', 'redo', '|', "insertTable", "tableColumn", "tableRow", "mergeTableCells", '|' ],
                    ckfinder: {
                    // Upload the images to the server using the CKFinder QuickUpload command
                    // You have to change this address to your server that has the ckfinder php connector
                    uploadUrl: 'https://cksource.com/weuy2g4ryt278ywiue/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json'
                    }
                    }}
                   
                  onInit={ editor => {
                      // You can store the "editor" and use when it is needed.
                      console.log( 'Editor is ready to use!', editor );
                  } }
                  onChange={ ( event, editor ) => {
                      const data = editor.getData();
                      let charCnt = data.replace(/<[^>]+>/g, '')
                      this.setState({ data : data});
                      this.setState({ dataCnt : charCnt.length});
                      //console.log( { event, editor, data } );
                      //console.log( { event, editor, data } );
                     //console.log('countchar', charCnt.length);
                  } }
                  onBlur={ ( event, editor ) => {
                      console.log( 'Blur.', editor );
                  } }
                  onFocus={ ( event, editor ) => {
                      console.log( 'Focus.', editor );
                  } }
                  

                  
              />
            </Row>
        </Form>
        </Modal>
        {/* modal1 */}


        {/* modal2 */}
        <Modal
        title="Add Your Remainder"
        style={{ top: 20 }}
        visible={this.state.modal2Visible}
        onOk={() => this.setModal2Visible(false)}
        onCancel={() => this.setModal2Visible(false)}
        maskClosable={false}
        footer={[
                        
            <Button key="submit" type="primary" loading={loading} onClick={this.handleRemainder} className="share_icon btn-primary">
            <Icon type="edit" />Create
            </Button>,
            <Button key="submit" type="cancel" loading={loading} data-id="2" onClick={this.handleClose} className="share_icon cancel_icon">
            <Icon type="close" />Cancel
            </Button>,
        ]}>
          <Form >
           <Row>
               <Col xs={12} sm={12}>
                   <Form.Item label="Reminder Datetime" className="mb-0">
                       {getFieldDecorator('notes_remainder', {
                       })(<DatePicker onChange={this.getDatepicker} />)}
                   </Form.Item>
               </Col>
           </Row>
       </Form>
        </Modal>
        {/* modal2 */}

        {/* modal3 */}
        <Modal
            title="Share Sticky pad"
            style={{ top: 20 }}
            visible={this.state.modal3Visible}
            onOk={() => this.setModal3Visible(false)}
            onCancel={() => this.setModal3Visible(false)}
            maskClosable={false}
            footer={[
                        
            <Button key="submit" type="primary" loading={loading} onClick={this.handleShare} className="share_icon">
                <Icon type="share-alt" />Share
            </Button>,
            <Button key="submit" type="cancel" loading={loading} data-id="3" onClick={this.handleClose} className="share_icon cancel_icon">
            <Icon type="icon-close" /><Icon type="close" />Cancel
        </Button>,
            ]}>
            <Row>
                <Col xs={3} sm={3} className="form-group">
                    <span className="float-left">Email</span>
                </Col>
                <Col xs={9} sm={9} className="form-group">
                    <Input placeholder="Enter Email" onChange={e => this.setState({ email: e.target.value })} />
                </Col>
            </Row>
            <Input type="hidden"
                    onChange={e => this.setState({ noteID: e.target.value })} value={this.state.noteID} />
            <Row>
                <Col xs={3} sm={3} className="form-group">
                    <span className="float-left">Permission</span>
                </Col>
                <Col xs={9} sm={9} className="form-group">
                <Select
                    onChange={this.handleOnChangePermission} 
                    showSearch
                    style={{ width: 200 }}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="1">Can Edit & View</Option>
                    <Option value="2">Can View</Option> 
                    <Option value="3">Can Edit </Option>
                    
                </Select>
                   {/* <Button>
                    <Dropdown overlay={menu1} trigger={['click']} className="dpdn-style">
                        <a href="JavaScript:void(0);" className="ant-dropdown-link" href="#">
                        Can Edit & View <Icon type="down" />
                        </a>
                    </Dropdown>
                   </Button> */ }
                </Col>
            </Row>
        </Modal>
        {/* modal3 */}
        
        {/* modal6 */}
        <Modal className="modal-imgs"
        title={this.state.notes_title}
        style={{ top: 20 }}
        //style={this.state.divWidth}
        visible={this.state.modal6Visible}
        onOk={() => this.setModal6Visible(false, this.state.value)}
        onCancel={() => this.setModal6Visible(false, this.state.value)}
        maskClosable={false}
        footer={[
            <Button style={{display : 'none'}} key="submit" type="cancel" loading={loading} data-id="5" onClick={this.handleClose} className="btn-close">
            <Icon type="close" />
            </Button>,
        ]}>
          <Row>
          <div class="c-sec"><p>{ renderHTML(this.state.notes) }</p></div>
          </Row>
        </Modal>
        {/* modal6 */}
      </div>
      
    );
  }

}

Note.defaultProps ={
  defaultColor : "#999999",
  title : "Color Picker",
  labelStyle : {
    paddingBottom: "7px",
    fontSize : "11px"
  },
  colorTextBoxStyle : {
    height : "35px",
    border : "none",
    borderBottom : '1px solid lightgray',
    paddingLeft : "35px"
  },
}
const WrappedNoteForm = Form.create()(Note);
const mapStateToProps = ({auth}) => {
  const {authUser,displayName, user_id,user_email} = auth;
  return {
    authUser,displayName,user_id,user_email}
};
export default connect(mapStateToProps) (WrappedNoteForm);



