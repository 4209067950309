import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import footlogo from '../assets/images/hexanotes-logonew.png';
import phone from '../assets/images/addphone.png';
import email from '../assets/images/addmail.png';
import classNames from 'classnames';
import { Icon } from 'antd';
import { Link } from 'react-router-dom';
import IntlMessages from "../util/IntlMessages";
//import { Link as Links,DirectLink,Element,Events,animateScroll as scroll,scrollSpy,scroller } from "react-scroll";
// const IconFont = Icon.createFromIconfontCN({
//     scriptUrl: '//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js',
//   });

class Footer extends React.Component {
    render(){
      return(
        <div className={classNames}> 
            <Grid fluid className="grey-bg">
                <Grid container>
                    <Row>
                        <Col xs={12} sm={3} className="footimg">
                            <Link to="/index"><img src={footlogo} alt="img" className="ptb-5" /></Link>
                            <p className="copy-right">Copyright <IntlMessages id="project.title"/> {new Date().getFullYear()} ©. All rights reserved.</p>
                        </Col>
                        <Col xs={12} sm={4} className="cont-detail">
                            <p className="foot-title">contact details</p>
                            <div className="foot_1">
                                <p><img className="img-space" src={phone} alt="img" />+1-801 362 1597</p>
                                <p><img className="img-space" src={email} alt="img" />info@hexagonitsolutions.com</p>
                            </div>
                        </Col>
                        <Col xs={12} sm={5} className="foot_1">
                            <p className="foot-title">legal terms</p>
                            <Row>
                                <Col xs={6}>
                                    <p><Link to="/helpsupport">Help & Support</Link></p>
                                    <p><Link to="/Termsconditions">Terms and Conditions</Link></p>
                                    <p><Link to="/Privacypolicy">Privacy Policy</Link></p>
                                </Col>
                                <Col xs={6}>
                                    <p> <Link to="/index?regRef=register">Register</Link></p>
                                    <p><Link to="/signin">Login</Link></p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Grid>
            </Grid>{/* end grid */}

            <Grid fluid className={classNames("lightgrey-bg")}>
                <Grid container className={classNames("foot-btm")}>
                    <Row>
                        <a href="https://www.facebook.com/Hexanotes-517017731836908/">
                          <p>{<Icon className={classNames("icon-fb")} type="facebook" />}Facebook</p>
                        </a>
                        {/* <a href="#/"><p>{<Icon className={classNames("icon-twt")} type="twitter" />}Twitter</p></a> */}
                    </Row>
                </Grid>
            </Grid>
        </div>
      );
  }
};

export default Footer;