import React from 'react';
import './App.css';

import {ConnectedRouter}  from "react-router-redux";
import {Provider} from "react-redux";
import {Route, Switch} from "react-router-dom";
import configureStore, {history} from "./appRedux/store";
import "./firebase/firebase";
import App from "./containers/App/index";

export const store = configureStore();

const NextApp = () =>
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" component={ App }/>
      </Switch>
    </ConnectedRouter>
  </Provider>;


export default NextApp; 