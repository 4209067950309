import React, {Component} from "react";
import {Layout} from "antd";
import CustomScrollbars from "../../util/CustomScrollbars";
import languageData from "./languageData";
import {switchLanguage, toggleCollapsedSideNav} from "../../appRedux/actions/Setting";
import SearchBox from "../../components/SearchBox";
import Auxiliary from "../../util/Auxiliary";
import UserProfile from "../../containers/Sidebar/UserProfile";
import Leftmenu1 from "../../components/Admin/Partial/leftpushmenu1";


import { NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR } from "../../constants/ThemeSetting";
  /* THEME_TYPE_LITE, NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE */
import {connect} from "react-redux";

const {Header} = Layout;

class Topbar extends Component {

  state = {
    searchText: '',
  };

  languageMenu = () => (
    <CustomScrollbars style={{ width: 500, height: 300 }} className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language =>
          <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) =>
            this.props.switchLanguage(language)
          }>
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`}/>
            <span className="gx-language-text">{language.name}</span>
          </li>
        )}
      </ul>
    </CustomScrollbars>);

  updateSearchChatUser = (evt) => {
    this.setState({
      searchText: evt.target.value,
    });
  };

  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  render() {
    const { navStyle} = this.props;
    let content = '';  
    if(localStorage.getItem('uIDS')!==0){
      if(localStorage.getItem('userIDS')!==0){
            content = 'show';
      }else{
            content = 'hide';
      }

    }else if(localStorage.getItem('uIDS')===0){
      content  = 'show';
    }
     return (
      <Auxiliary>
        <Header>
        <Leftmenu1 />
         <div className="gx-linebar gx-mr-3">
            <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
               {content!=='hide' ? <SearchBox 
                  placeholder="Search..."
                  onChange={this.updateSearchChatUser.bind(this)}
                  value={this.state.searchText}/>:''}
            </div>
            {content!=='hide' ? <UserProfile/>:''}
            </div> 
           
       
          </Header>
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({settings}) => {
  const {locale, navStyle, navCollapsed, width} = settings;
  return {locale, navStyle, navCollapsed, width}
};

export default connect(mapStateToProps, {toggleCollapsedSideNav, switchLanguage})(Topbar);
