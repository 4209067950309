import React from 'react';
import banner from '../assets/images/slider1.png';
import { Button, Divider, Typography } from 'antd';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import btm_img from '../assets/images/set1.png';
import blueimg from '../assets/images/make-note.png';
import { Grid, Row, Col } from 'react-flexbox-grid';
import editimg from '../assets/images/edit.png';
import shareimg from '../assets/images/share.png';
import cloudimg from '../assets/images/cloud.png';
import easynote from '../assets/images/easynote2.png';
import WrappedNormalLoginForm from '../components/myformm';
import Footer from '../components/footer';
import queryString from 'query-string';
import {
    Element,
    
  } from "react-scroll";
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
    
const { Text } = Typography;

class HomeMenu extends React.Component {
    constructor(props){
        super(props);
       
          this.state = {
            regRefe : '',
            editMode:'',
          };
          
      }
     
   
    componentDidMount() {
        document.title = "FileDrive - Login"
        if(this.props.location.search!==''){
          const values = queryString.parse(this.props.location.search)
          let register = values.regRef;
          this.setState({regRefe : register})
          //window.scrollTo(0, 5500);
          this.scrollToBottom();
         }else{ this.setState({regRefe : 'register'})}
        } 

  componentDidUpdate() {
    if(this.props.location.search!==''){
    this.scrollToBottom();
    }
  }
  scrollToBottom() {
    this.el.scrollIntoView({ behavior: 'smooth' });
  }
        
render(){
    return (
      <div className={classNames("wrapper")}> 
       <Zoom out>
          <section>
                <img className={classNames("ban-img", "w-100")} src={banner} alt="Logo" />
                <Fade right>
                <div className={classNames("ban-caption")}>
    <h1 className={classNames("text-white", "title-style")}> its easy and free!</h1>
                <p className={classNames("ban-para")}>Big ideas, little details, and everything in between. Anything that matters to you can be captured in a note, ready for when you need it.</p>
                <Button className={classNames("ban-btn")}><Link to="/index?regRef=register">GET START NOW</Link></Button>
                </div>
                </Fade>
          </section>
                    
          <Grid fluid className={classNames("btm-imgstyle")}>{/* grid */}
              <Grid container>
                  <Grid Row="xs">
                      <Col xs={12}>
                          <img className={classNames("mediaw-100")} src={btm_img} alt="Logo" />
                      </Col>
                  </Grid>
              </Grid>
          </Grid>
          
          <Grid fluid className={classNames("blue-back")}>{/* grid */}
              <Grid container>
                  <Row around="xs">
                      <Col xs={12}>
                            <Text className={classNames("blue-title")}>make note of everything!</Text>
                            <p className={classNames("pgraph-style", "text-center")}>Inspiration and Creativity flows out without time. Make note of all that you have in your mind across devices and share with your friends and family.</p>
                            <img className={classNames("mediaw-100")} src={blueimg} alt="Logo" />
                      </Col>
                  </Row>
              </Grid>
          </Grid>

          
          <Grid fluid className={classNames("blue-back-full")}> {/* grid */}
            <Grid container> 
              <Row around="xs">
                <Text className={classNames("blue-title")}>how does it work?</Text>
                <p className={classNames("pgraph-style", "text-center")}>Its simple as counting one, two and three. Just Create, Share and Collaborate.</p>
              </Row>
              <Row around="xs">
                <Col xs={12} sm={4} md={4} className="tri-steps">
                    <img className={classNames("s-img")} src={editimg} alt="Logo" />
                    <p className={classNames("testi-para")}>1.create</p>
                    <p className={classNames("testi-text")}>Just bring down your ideas and creations as notes and images.</p>
                </Col>
                <Col xs={12} sm={4} md={4} className="tri-steps">
                    <img className={classNames("s-img")} src={shareimg} alt="Logo" />
                    <p className={classNames("testi-para")}>2.upload</p>
                    <p className={classNames("testi-text")}>Upload your ideas or write down the notes in HexaNotes</p>
                </Col>
                <Col xs={12} sm={4} md={4} className="tri-steps">
                    <img className={classNames("s-img")} src={cloudimg} alt="Logo" />
                    <p className={classNames("testi-para")}>3.share and sync</p>
                    <p className={classNames("testi-text")}>Share your ideas with your mates and sync across devices.</p>
                </Col>
                <Divider className={classNames('mt-minus')} />
              </Row>
            </Grid>  
          </Grid>

          
            <Grid fluid className={classNames("p-100", "grey-clr-bg")}>
                <Row around="xs">
                    <Col xs={12} sm={1} md={1} lg={1} xl={1}></Col>
                    <Col className={classNames("text-left", "mt-19", "m-cont-center")} xs={12} sm={4} md={12} lg={4} xl={4}>
                        <Text className={classNames("black-title")}>lets do the fun together!</Text>
                        <p className={classNames("black-text", "para-graph")}>with option to create albums and view the images on timeline, its time to recollect your memorable events with your friends and family.</p>
                        <Button className={classNames("learn-btn")}>learn more</Button>
                    </Col>
                    <Col xs={12} sm={7} md={12} lg={7} xl={7}>
                        <img className={classNames("easy-note", "w-100")} src={easynote} alt="img" />
                    </Col>
                </Row>
            </Grid>
        <div ref={el => { this.el = el; }} >
        <Element id="register" className="element" >
          <Grid fluid className="bk_img" id="hexaRegister"> 
              <Grid container>
                  <Row around="xs">
                        <Col xs={12} sm={4} className="white-bg">
                            <Text className={classNames("black-title")}>join us</Text>
                            <p className={classNames("black-text", "foot-text")}>to start sharing your idea</p>
                            <WrappedNormalLoginForm />
                        </Col>
                        <Col xs={12}>
                            <p className="log_para">By clicking Sign up, I agree to the 
                            <a href="#/">Terms of Service</a> and 
                            <a href="#/"> Privacy Policy.</a></p>
                        </Col>
                  </Row>
              </Grid>
          </Grid>
          </Element>
          </div>
          </Zoom>
          <Footer />
      </div>
    );  
  }
};

export default HomeMenu; 