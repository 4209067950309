//let baseURL = 'http://localhost/hexanotes/server/public/';
let baseURL = 'https://server.filedrive.us/';
let fileURL ='https://files.filedrive.us/';
let driveURL = 'https://drive.filedrive.us/';
let dropboxURL = 'https://dropbox.filedrive.us/';
let cloudURL = 'https://filecloud.filedrive.us/';
let sharepointURL = 'https://sharepoint.filedrive.us/';
//export default baseURL;
export {
    baseURL,
    fileURL,
    driveURL,
    dropboxURL,
    cloudURL,
    sharepointURL
  };